/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import useForm, { FormContext } from 'react-hook-form';
import _ from 'lodash';
import LayoutContext from '../layout/layoutContext';
import getQuoteStyles from './getQuote.module.scss';
import { services } from '../../constants';
import { AddressAutocomplete, Select, Button } from '..';

const GetQuote = ({ formValues }) => {
    const {
        main,
        row,
        container,
        serviceSelect,
        autocomplete,
        buttonRow,
        textarea,
        labeledInput,
        leftInput,
        textAreaLabel,
        agreement,
        errorText,
        input,
        invalidInput,
        line,
    } = getQuoteStyles;
    const methods = useForm();
    const { getValues, handleSubmit, register, errors } = methods;
    const { setIsLoading, setHasCompleted } = useContext(LayoutContext);
    const { selectedService, selectedAddress } = formValues;

    useEffect(() => {
        const field = 'xxTrustedFormCertUrl';
        const provideReferrer = false;
        const invertFieldSensitivity = false;
        const tf = document.createElement('script');
        tf.type = 'text/javascript';
        tf.async = true;
        tf.src = _.concat(
            'https://api.trustedform.com/trustedform.js?provide_referrer=',
            escape(provideReferrer),
            '&field=',
            escape(field),
            '&l=',
            new Date().getTime(),
            Math.random(),
            '&invert_field_sensitivity=',
            invertFieldSensitivity
        );
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(tf, s);
    }, []);

    const submitForm = () => {
        setIsLoading(true);
        const {
            additional,
            addressAutocomplete,
            email,
            f_name,
            l_name,
            phone,
            select,
        } = getValues();

        const { zip, formatted_address } = addressAutocomplete;
        const task_id = select.option.value;

        axios
            .post(`${process.env.WIKILAWN_API_ENDPOINT}/networx/leads`, {
                f_name,
                l_name,
                phone,
                email,
                task_id,
                zipcode: zip,
                address: formatted_address,
                additional,
                trusted_form_id: _.get(window, 'trustedForm.id'),
            })
            .then(() => {
                setHasCompleted(true);
                // wait for the completed screen to be shown to user for 1 second before dismissing
                setTimeout(() => {
                    setIsLoading(false);
                    goBack();
                }, 1000);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.warn(error);
                setIsLoading(false);
                setHasCompleted(false);
            });
    };

    const goBack = () => {
        window.history.back();
    };

    const errorMessage = () => {
        return <p className={errorText}>This is required</p>;
    };

    return (
        <div className={container}>
            <div className={main}>
                <h2>Get A Quote</h2>
                <FormContext {...methods}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className={row}>
                            <div className={serviceSelect}>
                                <Select
                                    initialValue={selectedService || null}
                                    placeholder="Choose Service"
                                    options={services}
                                />
                            </div>
                            <div className={line} />
                            <div className={autocomplete}>
                                <AddressAutocomplete
                                    initialValue={selectedAddress}
                                    name="addressAutocomplete"
                                />
                            </div>
                        </div>
                        <div className={row}>
                            <div className={`${labeledInput} ${leftInput}`}>
                                <label htmlFor="f_name">
                                    First Name *
                                    <input
                                        ref={register({ required: true })}
                                        name="f_name"
                                        data-testid="f_name"
                                        type="text"
                                        placeholder="First Name"
                                        className={errors.f_name ? invalidInput : input}
                                    />
                                </label>
                                {errors.f_name && errorMessage()}
                            </div>
                            <div className={labeledInput}>
                                <label htmlFor="l_name">
                                    Last Name *
                                    <input
                                        ref={register({ required: true })}
                                        name="l_name"
                                        data-testid="l_name"
                                        type="text"
                                        placeholder="Last Name"
                                        className={errors.l_name ? invalidInput : input}
                                    />
                                </label>
                                {errors.l_name && errorMessage()}
                            </div>
                        </div>
                        <div className={row}>
                            <div className={`${labeledInput} ${leftInput}`}>
                                <label htmlFor="phone">
                                    Phone *
                                    <input
                                        ref={register({
                                            required: true,
                                            // https://stackoverflow.com/questions/8634139/phone-validation-regex
                                            pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                        })}
                                        name="phone"
                                        data-testid="phone"
                                        type="tel"
                                        placeholder="___-___-____"
                                        className={errors.phone ? invalidInput : input}
                                    />
                                </label>
                                {errors.phone && errorMessage()}
                            </div>
                            <div className={labeledInput}>
                                <label htmlFor="email">
                                    Email *
                                    <input
                                        ref={register({
                                            required: true,
                                            pattern: /^\S+@\S+\.\S+$/i,
                                        })}
                                        data-testid="email"
                                        name="email"
                                        type="email"
                                        placeholder="name@lawncareneeded.com"
                                        className={errors.email ? invalidInput : input}
                                    />
                                </label>
                                {errors.email && errorMessage()}
                            </div>
                        </div>

                        <label htmlFor="additionalDetails" className={textAreaLabel}>
                            Additional Details (optional)
                            <textarea
                                name="additional"
                                ref={register}
                                className={textarea}
                                type="textarea"
                            />
                        </label>

                        <p className={agreement}>
                            * By submitting this form, you agree to be contacted about your project
                            by Homeadvisor and / or other service partners using automated phone
                            technology and / or email, including for marketing purposes.
                        </p>

                        <div className={buttonRow}>
                            <Button secondary onKeyDown={goBack} onClick={goBack} text="X" />
                            <Button
                                onKeyDown={handleSubmit(submitForm)}
                                onClick={handleSubmit(submitForm)}
                                text="Submit"
                                data-testid="submit"
                                type="submit"
                            />
                        </div>
                    </form>
                </FormContext>
            </div>
        </div>
    );
};

GetQuote.propTypes = {
    formValues: PropTypes.shape({
        selectedService: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
        selectedAddress: PropTypes.shape({
            street: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zip: PropTypes.string,
            formatted_address: PropTypes.string,
        }),
    }),
};

GetQuote.defaultProps = {
    formValues: { selectedService: null, selectedAddress: null },
};

export default GetQuote;
