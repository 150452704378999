/* eslint-disable camelcase */
import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql } from 'gatsby';
import { Layout } from '../components';
import GetQuote from '../components/getQuote/getQuote';

import getQuoteStyles from './styles/get-quote.module.scss';

const GetQuotePage = ({ data, location }) => {
    const { backgroundImageContainer, backgroundImage, container } = getQuoteStyles;
    const { metaTitle, metaDescription } = _.head(data.graphCMS.getQuotePages);
    const selectedAddress = _.get(location, 'state.selectedAddress', null);
    const selectedService = _.get(location, 'state.selectedService', null);

    return (
        <div className={container}>
            <div className={backgroundImageContainer}>
                <Img
                    fluid={data.placeholderImage.childImageSharp.fluid}
                    className={backgroundImage}
                    backgroundColor="#0c8044"
                    alt="background image"
                />
            </div>
            <Layout metaTitle={metaTitle} metaDescription={metaDescription}>
                <GetQuote formValues={{ selectedAddress, selectedService }} />
            </Layout>
        </div>
    );
};

export const pageQuery = graphql`
    query GetQuotePageQuery {
        graphCMS {
            getQuotePages(last: 1) {
                id
                metaTitle
                metaDescription
            }
        }
        placeholderImage: file(relativePath: { eq: "lawn-background-full.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1400, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;

GetQuotePage.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            getQuotePages: PropTypes.arrayOf(
                PropTypes.shape({
                    metaTitle: PropTypes.string,
                    metaDescription: PropTypes.string,
                }),
            ),
        }),
        placeholderImage: PropTypes.shape({
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.object,
            }),
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            selectedAddress: PropTypes.shape({
                street: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                zip: PropTypes.string,
                formatted_address: PropTypes.string,
            }),
            selectedService: PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default GetQuotePage;
